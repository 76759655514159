import React from "react";
import InputSelect from "./input-select";
import { UseFormReturnType } from "@mantine/form";
import { Icon } from "@iconify/react/dist/iconify.js";

type Props<TData> = {
  form: UseFormReturnType<TData>;
  name: string;
};

function InputSelectBranch<TData>({ form, name }: Props<TData>) {
  return (
    <InputSelect
      label="Unit Kerja"
      placeholder="Pilih unit kerja"
      aria-label="branch"
      withAsterisk
      data-cy="branch"
      form={form}
      name={name}
      leftSection={<Icon icon="tabler:building-hospital" className="h-4 w-4" />}
      fetchData={{
        urlPath: "/data/branch/opts",
        keys: { label: "nama", value: "kode" },
        dataNestedKeys: "data.branchOptions",
      }}
    />
  );
}

export default InputSelectBranch;
